import React from 'react'
import { FaFacebook, FaTelegram, FaPhone, FaEnvelope, FaWhatsapp } from 'react-icons/fa';

const Footer = () => {
  return (
  <React.Fragment>
    <div className='footer'>
        <div className='footer-box'>
            <h2>About Us</h2>
            <p>Uttertech Consulting and Technology
             solutions PLC( Uttertech) is a technology company engaged in digitalization and supply of ICT equipments.</p>
        </div>
        <div className='footer-box'>
            <h2>Contact Us</h2>
            <p><FaPhone /> <span>+251920251598</span></p>
            <p><FaPhone /> <span>+251911037049</span></p>
            <p><FaEnvelope /><span>uttertechplc@uttertech.com.et</span></p>
        </div>
        <div className='footer-box'>
        <h2>Links</h2>
            <p><a href='#'>ERP</a></p>
            <p><a href='elts.com.et'>elts</a></p>
            
            
        </div>
        <div className='footer-box'>
        <h2>media</h2>
        <div className='media'>
       <h3><a href='https://www.facebook.com/profile.php?id=61561388647979'> <FaFacebook style={{ color: 'white', fontSize: '30px', }}/></a></h3>
       <h3><a href='https://t.me/+nTF3Q9ABf6c2ZGI0'><FaTelegram style={{ color: 'white', fontSize: '30px' }}/></a></h3>
       <h3><a href='https://chat.whatsapp.com/KtxiBg20AAu5wJ4HOSRkIA'><FaWhatsapp style={{ color: 'white', fontSize: '30px' }}/></a></h3>
       </div>
        </div>
    </div>
    <div className="foot">
    <p>Copyright &#169;2024 <span id="year"></span>Uttertech Consulting and Technology Solution</p>
    
    </div>
  </React.Fragment>
  )
}

export default Footer