import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import UpdateReview from './UpdateReview';



const Reviews = () => {
  const [images, setImages] = useState([]);
  const [review, setReview] = useState([])
  const apiurl = "https://back.uttertech.com.et";
  useEffect(() => {
    const fetchImages = async () => {
      try {
        const response = await axios.get(`${apiurl}/app/fetch-review`);
        setImages(response.data);
      } catch (error) {
        console.error('Error fetching images', error);
      }
    };

    fetchImages();
  }, []);

  //delete review function
  const deleteReview = async (id) => {
    try{
const deleteReview = await fetch(`apiurl/api/deletereview/${id}`,{
  method: "DELETE"
});
setReview(review.filter(data => data.id !== id));
    }catch (err){
      console.error(err.message)
    }
  }
  return (
    <div className='px-5 mt-3'>
    <div className='d-flex justify-content-center'>
        <h3>Review list</h3>
    </div>
    <Link to="/dashboard/add_review" className='btn btn-success'> Add Review</Link>
    <div className='mt-3'>
            <table className='table'>
                <thead>
                    <tr>
                        <th>name</th>
                        <th>job</th>
                        <th>Description</th>
                        <th>image</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                 
                      {images.length > 0 ? (
                        images.map(data => (
                          <tr key={data.id}>
                             <td>{data.name}</td>
                             <td>{data.job}</td>
                             <td>{data.description}</td>
                             <td> <img 
                              style={{width:"100px"}} 
                              src={`data:image/jpeg;base64,${data.data}`} 
                              alt={data.name} 
                            />
                             {/* <UpdateUserImage userId={data.id} /> */}
                            </td>
                            <td>
                              <UpdateReview data={data}/>
                              <button className='btn btn-danger ml-3' onClick={() => deleteReview(data.id)}>delete</button>
                            </td>
                             
                            
                           {/* <td><button className='btn btn-danger'
                           onClick={() => deleteNews(data.id)}>delete</button>
                           </td> */}
                          
                           
                           
                           
                          </tr>
                        ))
                      ) : (
                        <p>No images found.</p>
                      )}
                   
                   
                 
                </tbody>
            </table>
    
    </div>
    </div>
  )
}

export default Reviews