
import React, { useState } from "react";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { AuthProvider } from './context/AuthContext';
import Home from "./component/page/Home";
import About from "./component/page/About";

import Service from "./component/page/Service";

import Contact from "./component/page/Contact";
import Login from "./component/Login";
import Register from "./component/page/Register";
import Dashboard from "./component/page/Dashboard";

import News from "./component/page/News";
import Users from "./component/page/Users";
import AddNews from "./component/page/AddNews";
import Reviews from "./component/page/Reviews";
import AddReview from "./component/page/AddReview";
// import FetchImage from "./component/page/FetchImage";
import UploadImage from "./component/page/UploadImage";
import UpdateNews from "./component/page/UpdateNews";
import UpdateReview from "./component/page/UpdateReview";
import ChangePassword from "./component/ChangePassword";
import AdminDashboard from "./component/AdminDashboard";
import ResetPassword from "./component/ResetPassword";
import ResetPasswordRequest from "./component/ResetPasswordRequest";
import Signup from "./component/Signup";
import Privacy from "./component/page/Privacy";


function App() {

  return (
    <AuthProvider>
      <Router>
   <div>
   
        <Routes>
            <Route path='/' element={<Home/>}></Route>
            <Route path='/about' element={<About/>}></Route>
            <Route path='/service' element={<Service/>}></Route>
            <Route path='/contact' element={<Contact/>}></Route>
            {/* <Route path='/login' element={<Login/>}></Route> */}
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<Signup />} />
            <Route path='/register' element={<Register/>}></Route>
            <Route path='/dashboard' element={<AdminDashboard/>}>
            <Route path='/dashboard/user' element={<Users/>}></Route>
            <Route path='/dashboard/news' element={<News/>}></Route>
            <Route path='/dashboard/reviews' element={<Reviews/>}></Route>
            <Route path='/dashboard/add_news' element={<AddNews/>}></Route>
            <Route path='/dashboard/add_review' element={<AddReview/>}></Route>
            <Route path='/dashboard/update-news' element={<UpdateNews/>}></Route>
            <Route path='/dashboard/update-reviews' element={<UpdateReview/>}></Route>
            </Route>
           {/* <Route path="/upload" element={<UploadImage/>}></Route> */}
           {/* <Route path="/fetch-image" element={<FetchImage/>}></Route> */}
           <Route path='/update-news' element={<UpdateNews/>}></Route>
           <Route path='/password' element={<ChangePassword/>}></Route>

           <Route path="/reset-password" element={<ResetPasswordRequest />} />
           <Route path="/reset-password/:token" element={<ResetPassword />} />
           <Route path="/change-password" element={<ChangePassword />} />

           <Route path="/privacy" element={<Privacy/>} />
           
          
        </Routes>
        
        
    </div>
    </Router>
    </AuthProvider>
  );
}

export default App;
