import React from 'react'
import Navbar from '../Navbar'
import Footer from './Footer'
import { FaFacebook, FaTelegram, FaPhone, FaEnvelope, FaWhatsapp } from 'react-icons/fa';

const Contact = () => {
    return (
        <React.Fragment>
            <Navbar />
            <div className="about-page">

                <h2>Contact Us</h2>
            </div>
            <div className='contact'>
            <div className='contact-box'>
            <h2>contact </h2>
            <p><FaPhone /> <span>+251920251598/+251911037049/+251942332735</span></p>
            <p><FaEnvelope /><span>uttertechplc@gmail.com</span></p>
            </div>
            <div className='contact-box'>
            <h2>media</h2>
         <a href='https://www.facebook.com/profile.php?id=61561388647979'><FaFacebook style={{ color: 'blue', fontSize: '30px', }}/></a>
         <a href='https://t.me/+nTF3Q9ABf6c2ZGI0'><FaTelegram style={{ color: 'blue', fontSize: '30px' }}/></a>
         <a href='https://chat.whatsapp.com/KtxiBg20AAu5wJ4HOSRkIA'><FaWhatsapp style={{ color: 'blue', fontSize: '30px' }}/></a>
            </div>
        </div>
            
                {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d125074.6735749245!2d37.29802098377719!3d11.581683395801056!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1644d23307d78069%3A0xab0b134f632dff8!2sBahir%20Dar!5e0!3m2!1sen!2set!4v1716282065845!5m2!1sen!2set"

                  width="100%" height="450" 
                  style={{border:"0"}}
                  allowfullscreen=""
                   loading="lazy" 
                  referrerpolicy="no-referrer-when-downgrade">

                  </iframe> */}
           
            <Footer />
        </React.Fragment>

    )
}

export default Contact