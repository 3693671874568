import React from 'react'
import { FaHandPointRight } from 'react-icons/fa';

const HomeSite = () => {
  return (
    <React.Fragment>
      
         <div className='homesite-container'>
          <h2>Welcome to Uttertech Consulting and Technology Solutions</h2> 
       
        <div className='homesite'>
            <div className='content'>
                <ul >
                <h2>Digitalize your working Environment and Make your work and decisions:</h2>
                <li><FaHandPointRight style={{ color: '#212f3d', fontSize: '20px', }}/><span>Effective </span> </li>
                <li><FaHandPointRight style={{ color: '#212f3d', fontSize: '20px', }}/><span>Convinient </span> </li>
                <li><FaHandPointRight style={{ color: '#212f3d', fontSize: '20px', }}/><span>Transparent</span></li>
                <li><FaHandPointRight style={{ color: '#212f3d', fontSize: '20px', }}/><span>Ubiquitous</span> </li>
                </ul>
            </div>
            <div className='home-image'>
                <img src='images/background.jpeg' />
                </div>
                <div className='content'>
                <ul >
                <h2>Uttertech is ready to serve you on :</h2>
                <li><FaHandPointRight style={{ color: '#212f3d', fontSize: '20px', }}/><span>Software Development</span></li>
                <li><FaHandPointRight style={{ color: '#212f3d', fontSize: '20px', }}/><span>Consultancy Service</span></li>
                <li><FaHandPointRight style={{ color: '#212f3d', fontSize: '20px', }}/><span>ICT Equipments Supply</span></li>
                </ul>
            </div>
        </div>
        </div>
    </React.Fragment>
  )
}

export default HomeSite