import React from 'react'
import Navbar from '../Navbar'
import Footer from './Footer'
import TeamMember from './TeamMember'

const About = () => {
  return (
    <React.Fragment>
    <Navbar/>
    <section >
     <div className="about-page">

        <h2>About Us</h2>
     </div>
    </section>
    <section className="about-section">
         <div className="about">
             <div className="about_box1">
                 <h2>Uttertech Consulting</h2>
                 <p>
    Uttertech Consulting and Technology solutions PLC(Uttertech)
    is a technology company, established with the aim of creating technology 
    enabled digital platforms that can support Business, Education,Agriculture,
    Health and Financial sectors of the country.
     The founders of this company are experienced staff members of Bahir Dar University 
     (BDU),who had been working on teaching, research and software development
      projects for more than 15 years.</p>
      
             </div>
             <div className="about_box2">
             {/* <img src='images/UtterTech Brand.png'/> */}
             <h2>Software Products ready for use </h2><br/>
             <p>____<span>Enterprise Resource Planning (ERP)</span><br/>
             ____<span>Fleet Management System (FMS)</span><br/>
             ____<span>Human Resource Management System(HRMS)</span><br/>
             ____<span>electronic Procurement Management Systemrocurement(ePMS)</span><br/>
             ____<span>electronic Learning and Testing System(ELTS)</span><br/>
             ____<span>Donor Resource Traking and Monitoring System(DRTMS)</span></p><br/>
             </div>
         </div>
         
     </section>
     <br/>

     <section className='mission'>
         <div className="mission_vision">
         <div className="mision">
         <h2>Mision</h2>
        
             <p> <img src='images/missiom.png' />Our commitment is to assist our clients to modernize their woking style in their daily rootins and 
             redirect their resources towards ahieving organizational and personal goals. 
             To that end, we provide technologically innovating solutions to satisfy the customer's needs and expectations.</p>
         </div>
         <div className="vision">
        
         <h2>Vision</h2>
             <p> <img src='images/vission.png' />Our vision is to position and consolidate our company as a leading supplier
                  of Software products and services at a national and global level.With international projection, methodologies and standards, we use 
                   cutting-edge technologies that allow us to develop effective, reliable, scalable, and economic products that improve our costumers’ 
                   competitiveness</p>
         </div>
         </div>
     </section>
    <TeamMember/>
     <br/>
    <Footer/>
 </React.Fragment>
  )
}

export default About