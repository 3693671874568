import React from 'react'
import Navbar from '../Navbar'
import { Link} from 'react-router-dom' 

import "./style.css";
import Sample from './Sample';
// import HomePage from './HomePage';
import Footer from './Footer';
// import Review from './Review';
// import News from './News';
// import ViewNews from './ViewNews';
// import Announcement from './Announcement';
// import ReadNews from './ReadNews';
// import Pagination from './Pagination';
import ScrollToToButton from './ScrollToToButton';
// import Background from './Background';
// import Hero from './Hero';
import TeamMember from './TeamMember';
import HomeSite from './HomeSite';

const Home = () => {

 
  return (
    <React.Fragment>
    <Navbar/>

        {/* <HomePage/> */}
        <HomeSite/>
    {/* <br/> <br/>  */}
    <section className="about-section">
        <div className="about">
            <div className="about_box1">
                <h2>Uttertech Consulting</h2>
                <p>
   Uttertech Consulting and Technology solutions PLC(Uttertech)
   is a technology company, established with the aim of creating technology 
   enabled digital platforms that can support Business, Education,Agriculture,
   Health and Financial sectors of the country.
    The founders of this company are experienced staff members of Bahir Dar University 
    (BDU),who had been working on teaching, research and software development
     projects for more than 15 years.</p>
      <Link to={"/about"}className='btn btn-info readmore'>read more</Link>
            </div>
            <div className="about_box2">
            {/* <img src='images/UtterTech Brand.png'/> */}
    
    <h2>Software Products ready for use </h2><br/>
            <p>____<span>Enterprise Resource Planning (ERP)</span><br/>
            ____<span>Fleet Management System (FMS)</span><br/>
            ____<span>Human Resource Management System(HRMS)</span><br/>
            ____<span>electronic Procurement Management Systemrocurement(ePMS)</span><br/>
            ____<span>electronic Learning and Testing System(ELTS)</span><br/>
            ____<span>Donor Resource Traking and Monitoring System(DRTMS)</span></p><br/>
    
            </div>
        </div>
        <ScrollToToButton/>
        <br/>
        {/* <section className='add-news'>
          <div className='news'>
            <Pagination/>
          <News/>
          <Announcement/>
          </div>
          
        </section> */}
        
        
    </section>
 <section className='service'>
 <center > <p ><span >____</span>Services <span >____</span> </p></center>
 <div className='service_list'>
 <p >Application Development</p>
    <div className="service1">
    <div className="box">
    <img src='images/webapplication.jpg' />
  <h2 >Website and Web application </h2>
  <p > We design and develop interactive and well  structured websites for your business. We also design and develop web applications based on customer requirements and specifications</p>
    </div>
    <div className="box">
    <img src='images/mobileapp.jpg' />
  <h2  >Mobile application </h2>
  <p >We develop attractive and easy to use mobile  applications for both Android and IOS.</p>
    </div>
    <div className="box">
    <img src='images/desk.jpg' />
  <h2 >	Desktop application </h2>
  <p >We design and develop applications specifically needed to run on desktop computers.</p>
    </div>
    <div className="box">
    <img src='images/webhost.png' />
  <h2>Hosting </h2>
  <p >We provide hosting service for applications and websites</p>
    </div>
    </div>
    <p> Consultancy  </p>
    <div className="service2">
    <div className="odd_box">
      <p >	We provide consultancy services in software project development, requirement elicitation, software project evaluation and overall system development activities </p>
      <p >	We mentor and consult companies and businesses on using ICT for enhanced growth.</p>                 
    </div>
    </div>
    <br/>
    <br/>
    <p >ICT equipments supply </p>
    <div className="service3">
    <div className="box">
    <img src='images/server.jpg' />
  <h2 >servers </h2>
  <p >We Supply high tech servers hardwares for business and public entities .</p>
    </div>
    <div className="box">
    <img src='images/workstation.jpg' />
  <h2  >Work station </h2>
  <p >We Supply workstation computers.</p>
    </div>
    <div className="box">
    <img src='images/lab.jpg' />
  <h2 >	Laptops </h2>
  <p >We supply laptops for high performance computational needs such as advanced programming, graphics and machine learning .</p>
    </div>
    <div className="box">
    <img src='images/tablet.jpg' />
  <h2  >Tablets</h2>
  <p >We supply Tables of Apple and Samsung Galaxy products </p>
    </div>
    </div>
 </div>

 </section>

 <br/>
  <Sample/>
 
  {/* <Review/>
  <br/> */}
 <TeamMember/>
 
 
 <Footer/>
</React.Fragment>
  )
}

export default Home