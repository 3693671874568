// src/components/AdminDashboard.js
import React, { useContext, useEffect, useState } from 'react';
import { FaUser, FaPersonBooth, FaFile, FaPlusSquare, FaPowerOff, FaComment } from 'react-icons/fa';
import { Link, Outlet, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { AuthContext } from '../context/AuthContext';

const AdminDashboard = () => {
  const { auth, logout } = useContext(AuthContext);
  const navigate = useNavigate();
  const [message, setMessage] = useState('');
  const apiurl = "https://back.uttertech.com.et";

  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  useEffect(() => {
    const fetchMessage = async () => {
      const token = localStorage.getItem('token');
      if (token) {
        try {
          const response = await axios.get(`${apiurl}/dashboard`, {
            headers: {
              Authorization: `Bearer ${auth.token}`,
            },
          });
          setMessage(response.data.message);
        } catch (err) {
          console.error('Error fetching message:', err);
        }
      }
    };

    fetchMessage();
  }, []);

  return (
    // <div>
    //   <h2>Admin Dashboard</h2>
    //   <button onClick={handleLogout}>Logout</button>
    //   <button onClick={fetchProtectedData}>Fetch Protected Data</button>
    //   <h4 className='float-right'> Welcome  {message ? message : 'Loading...'}</h4>
    // </div>

    <React.Fragment>
      
    <div className='container-fluid'>
      <div className='row flex-nowrop'>
        <div className='col-auto col-md-3 col-x1-2 px-sm2 px-0 bg-dark'>
          <div className='d-flex flex-column align-items-center align-items-sm-start px-3 pt-2 text-white min-vh-100'>
            <Link to="/dashboard" className='d-flex align-items-center pb-3 mb-md-3 me-md-auto text-white text-decoration-none '>
              <span className='fs-5 fw-bolder d-none d-sm-inline'>Uttertech</span></Link> 
            <ul className='nav nav-pills flex-column mb-sm-auto mb-0 align-items-center align-items-sm-start'>
              <li className='w-100'>
                <Link to="/dashboard/signup"className='nav nav-link text-white px-0 align-middle'>
                <FaFile style={{ color: 'white', fontSize: '30px' }}
                />
                  <span className='ms-2 d-none d-sm-inline'>
                  Dashboard
                  </span></Link>
              </li>
              {/* <li>
              <Link to="/dashboard/user"className='nav nav-link text-white px-0 align-middle'>
              <FaUser style={{ color: 'white', fontSize: '30px' }}/>
                  <span className='ms-2 d-none d-sm-inline'>
                  User
                  </span></Link>
              </li> */}
              <li>
              <Link to="/dashboard/news"className='nav nav-link text-white px-0 align-middle'>
              <FaPlusSquare style={{ color: 'white', fontSize: '30px' }}/>
                  <span className='ms-2 d-none d-sm-inline'>
                  News
                  </span></Link>
              </li>
              <li>
              <Link to="/dashboard/reviews"className='nav nav-link text-white px-0 align-middle'>
              <FaUser style={{ color: 'white', fontSize: '30px' }}/>
                  <span className='ms-2 d-none d-sm-inline'>
                  Review
                  </span></Link>
              </li>
              <li>
              <Link to="/dashboard/contactlist"className='nav nav-link text-white px-0 align-middle'>
              <FaComment style={{ color: 'white', fontSize: '30px' }}/>
                  <span className='ms-2 d-none d-sm-inline'>
                  Contact
                  </span></Link>
              </li>
              {/* <li className='w-100' onClick={handleLogout}>
              <Link className='nav nav-link text-white px-0 align-middle'>
              <FaPowerOff style={{ color: 'white', fontSize: '30px' }}/>
                  <span className='ms-2 d-none d-sm-inline'>
                  Logout
                  </span></Link>
              </li> */}
            </ul>
          </div>
        </div>
        <div className='col p-0 m-0 shadow w-100 '>
          <div className='bg-dark w-100 vh-10'>
          <div className='p-2 d-flex float-right'>
            <h4 className='d-flex ' onClick={handleLogout}>
              <Link className=' nav-link  '>
              {/* <FaPowerOff style={{ color: 'white', fontSize: '30px' }}/>
                  <span className='ms-2 d-none d-sm-inline'> */}
                  signout
                  </Link>
              {message ? message : 'Loading...'}</h4>
            {/* <h5>{message ? message : 'Loading...'}</h5>  */}
          </div>
          </div>
          <Outlet/>
         
        </div>
        
      </div>
    </div>
</React.Fragment>
  );
};

export default AdminDashboard;
