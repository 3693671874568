import React from 'react'

const Sample = () => {
  return (
    <React.Fragment>
    <section className='sample'>
    <h2 className='sampleh2'>Completed and ready to use Systems</h2>
    
    <div className='sample_software'>
        {/* <div className='sample1'> */}
            
            <div className='sample-box'>
                <h2>e-Procurement Management System (ePMS)</h2>
                <p> It is one of the noteable software products developed by the founders of Uttertech, which is currently in use by Bahir Dar University. 
                    The software includes request submission and approval,bid document preparation ,bid evaluation, 
                    result notification and contract administration.
                   
                    </p>
                    {/* <center> <button className='btn btn-success'> <a href='#'>view site</a></button></center> */}

            </div>
            <div className='sample-box'> 
            <h2>Enterprise Resource Planning (ERP)</h2>
                <p> The ERP System  includes seven main modures such as HR,finance, procurement, sales, inventory, fleet and reporting. 
                   
                    </p>
                    {/* <center> <button className='btn btn-success'> <a href='#'>view site</a></button></center> */}
            </div>
            <div className='sample-box'>
            <h2>Donor Resource Tracking and Monitoring System(DRTMS)</h2>
            <p>
            It was initially developed for Amhara bureau of finance and economic cooperation. It includes important aspects of monitoring and evaluation for donor 
            funded projects. The system has the features of online submission of project roposal and approval. It also includes report generation of resouce distribution across 
            sectors and zones, by Donors 
                
                   
                    </p>
                    {/* <center> <button className='btn btn-success'> <a href='#'>view site</a></button></center> */}
            </div>
          
        {/* </div>
        <div  className='sample2'>  */}
        
   
         
       
        </div>
    {/* </div> */}
    </section>
</React.Fragment>
  )
}

export default Sample