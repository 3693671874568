import React from 'react'
import Navbar from '../Navbar'
import Footer from './Footer'

const Privacy = () => {
  return (
    <React.Fragment>
        <Navbar/>
    <div style={{ padding: '20px', maxWidth: '1000px', margin: '0 auto' }}>
<h3>Privacy Policy</h3>
<br/>
<br/>
<p>By using our service you agree to the collection and use of information in accordance with this policy.</p>
<h3>Information We Collect:</h3>
<h5>When you use our service we may collect the following types of information:</h5>
<p>- Personal Information: When you sign up for an account, we may collect your name, email address, and other contact information.</p>
<p>- Usage Information: We may collect information about how you use our [website/app/service], such as the pages you visit, the features you use, and the actions you take.</p>
<h3>How We Use Information</h3>
<p>We may use the information we collect for the following purposes:</p>
<ul>
<li>
<p> To improve our service and develop new features.</p>
</li>
<li>
<p> To communicate with you, including sending you updates and notifications about our [website/app/service].</p>
</li>
{/* <li>
<p><strong>Website</strong> refers to uttertech, accessible from <a href="https://uttertech.com.et" rel="external nofollow noopener" target="_blank">https://uttertech.com.et</a></p>
</li> */}

</ul>
<h3>Information Sharing</h3>
<p>We may share your information with third parties in the following circumstances:</p>
<ul>
<li>
<p>With your consent.</p>
</li>
<li>
<p>With service providers who help us operate our service. </p>
</li>
<li>
<p>In response to a legal request, such as a court order or subpoena.</p>
</li>

</ul>
<h4>Data Security</h4>
<p>We take reasonable measures to protect the information we collect from loss, theft, misuse, and unauthorized access.</p>
<h4>Changes to This Privacy Policy</h4>
<p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.</p>


<h3>Contact Us</h3>
<p>If you have any questions about this Privacy Policy, You can contact us:</p>
<ul>
<li>By email: uttertechplc@uttertech.com.et</li>
</ul>
<p>Last updated: July 22, 2024</p>

        </div>
        <Footer/>
        </React.Fragment>
  )
}

export default Privacy