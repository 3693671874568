// src/components/Signup.js
import React, { useState } from 'react';
import axios from 'axios';


const Signup = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [role, setRole] = useState('');
  const apiurl = "https://back.uttertech.com.et";

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${apiurl}/register`, {
        username,
        password,
        role,
      });
      console.log(response.data);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <React.Fragment>
   
    <div className='d-flex justify-content-center align-items-center vh-100'>
    <div className='bg-white p-3 rounded w-50'>
    <form onSubmit={handleSubmit}>
      <h2>Signup</h2>
      <div className="mb-3">
      <label htmlFor='name'><strong>User Name</strong></label>
      <input
        type="text"
        placeholder="Username" required
        value={username} className='form-control rounded-0'
        onChange={(e) => setUsername(e.target.value)}
      />
      </div>
      <div className="mb-3">
      <label htmlFor='name'><strong>Password:</strong></label>
      <input
        type="password"
        placeholder="Password" 
        value={password} className='form-control rounded-0'
        onChange={(e) => setPassword(e.target.value)}
      />
      </div>
      <div className="mb-3">
      <label htmlFor='name'><strong>Role:</strong></label>
      <select value={role} onChange={(e) => setRole(e.target.value)} className='form-control rounded-0'>
        <option value="" disabled>Select Role</option>
        <option value="admin">Admin</option>
      </select>
      </div>
      <button type="submit" className='btn btn-success w-100 rounded-0'>Signup</button>
    </form>
    </div>
    </div>
    </React.Fragment>
  );
};

export default Signup;
