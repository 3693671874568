import React from 'react'
import Footer from './Footer'
import Navbar from '../Navbar'

const Service = () => {
  return (

   <React.Fragment>
    <Navbar/>
    <div className="about-page">

<h2>Service</h2>
</div>
    <section className='service'>
 
 <div className='service_list'>
 <p >Application Development</p>
    <div className="service1">
    <div className="box">
    <img src='images/webapplication.jpg' />
  <h2 >Website and Web application </h2>
  <p > We design and develop interactive and well  structured websites for your business. We also design and develop web applications based on customer requirements and specifications</p>
    </div>
    <div className="box">
    <img src='images/mobileapp.jpg' />
  <h2  >Mobile application </h2>
  <p >We develop attractive and easy to use mobile  applications for both Android and IOS.</p>
    </div>
    <div className="box">
    <img src='images/desk.jpg' />
  <h2 >	Desktop application </h2>
  <p >We design and develop applications specifically needed to run on desktop computers.</p>
    </div>
    <div className="box">
    <img src='images/webhost.png' />
  <h2>Hosting </h2>
  <p >We provide hosting service for applications and websites</p>
    </div>
    </div>
    <p> Consultancy  </p>
    <div className="service2">
    <div className="odd_box">
      <p >	We provide consultancy services in software project development, requirement elicitation, soft project evaluation and overall system development activities </p>
      <p >	We mentor and consult companies and businesses on using ICT for enhanced growth.</p>                 
    </div>
    </div>
    <br/>
    <br/>
    <p >ICT equipments supply </p>
    <div className="service3">
    <div className="box">
    <img src='images/server.jpg' />
  <h2 >servers </h2>
  <p >We Supply high tech servers hardwares for business and public entities .</p>
    </div>
    <div className="box">
    <img src='images/workstation.jpg' />
  <h2  >Work station </h2>
  <p >We Supply workstation computers.</p>
    </div>
    <div className="box">
    <img src='images/lab.jpg' />
  <h2 >	Laptops </h2>
  <p >We supply laptops for high performance computational needs such as advanced programming, graphics and machine learning .</p>
    </div>
    <div className="box">
    <img src='images/tablet.jpg' />
  <h2  >Tablets</h2>
  <p >We supply Tables of Apple and Samsung Galaxy products </p>
    </div>
    </div>
 </div>

 </section>
     <br/>
     <Footer/>
   </React.Fragment>
  )
}

export default Service