// src/components/Login.js
// import ReCAPTCHA from "react-google-recaptcha";
import React, { useState, useContext } from 'react';
import axios from 'axios';
import { AuthContext } from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import Navbar from './Navbar';

const Login = () => {
  const onChange = () =>{};
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');
  const { login } = useContext(AuthContext);
  const navigate = useNavigate();
  const apiurl = "https://back.uttertech.com.et";

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${apiurl}/login`, {
        username,
        password,
      });
      login(response.data.token);
      
      navigate('/dashboard');
    } catch (err) {
      console.error(err);
      setMessage("unauthorized")
    }
  };

  return (
<React.Fragment>
    <Navbar/>
    <div className='d-flex justify-content-center align-items-center vh-100'>
    <div className='bg-white p-3 rounded w-25'>
    <form onSubmit={handleSubmit}>
      <h2>Login</h2>
      {message && <p className='text-danger'>{message}</p>}
      <div className="mb-5 p-2">
      <lable htmlFor="email"><strong>Username </strong></lable>
      <input
        type="text"
        placeholder="Username"
        value={username} className='form-control rounded-0'
        onChange={(e) => setUsername(e.target.value)}
      />
      </div>
      <div className="mb-5 pl-2">
      <lable htmlFor="email"><strong>Password </strong></lable>
      <input
        type="password"
        placeholder="Password"
        value={password} className='form-control rounded-0'
        onChange={(e) => setPassword(e.target.value)}
      />
      </div>
      {/* <ReCAPTCHA
    sitekey="6LeDvwkqAAAAAJmtfBFWwEdsUGQCq4v1wGhqI15J"
    onChange={onChange}
  /> */}
      <button type="submit"  className='btn btn-success w-100 '>Login</button>
    </form></div></div>
    </React.Fragment>
  );
};

export default Login;
