import React, {  useState } from 'react';
import { json } from 'react-router-dom';

const UpdateNews = ({data}) => {
    const [date, setDate] = useState(data.date)
    const [title, setTitle] = useState(data.title)
    const [description, setDescription] = useState(data.description)
    const apiurl = "https://back.uttertech.com.et";

    //edit description function
    const UpdateNews = async (e) => {
        e.preventDefault();
        try{
            const body ={date, title, description};
            const response = await fetch(`${apiurl}/api/updatenews/${data.id}`, {
                method: "put",
                headers: {"Content-Type": "application/json"},
                body: JSON.stringify(body)
            });
            window.location = "/dashboard/news";
           console.log(response) 
        }
        catch (err){
         console.error(err.message)
        }
    }
  return (
    <React.Fragment>

<button type="button" class="btn btn-warning" data-toggle="modal" data-target={`#id${data.id}`}>
  edit
</button>


<div class="modal" id={`id${data.id}`}>
  <div class="modal-dialog">
    <div class="modal-content">

     
      <div class="modal-header">
        <h4 class="modal-title">edit news</h4>
        <button type="button" class="close" data-dismiss="modal" 
        
        
        >&times;</button>
      </div>

     
      <div class="modal-body">
        <input type="text" className='form-control' value={date} 
        onChange={e => setDate(e.target.value)}/>
        <input type="text" className='form-control' value={title}
        onChange={e => setTitle(e.target.value)}/>
        <input type="text" className='form-control' value={description}
        onChange={e => setDescription(e.target.value)}/>
      </div>

    
      <div class="modal-footer">
      <button type="button" class="btn btn-warning" data-dismiss="modal" onClick={e => UpdateNews(e)}>edit</button>
        <button type="button" class="btn btn-danger" data-dismiss="modal">Close</button>
      </div>

    </div>
  </div>
</div>

        </React.Fragment>
  )
}

export default UpdateNews