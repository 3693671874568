import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const AddReview = () => {
  const navigate = useNavigate();
    const [file, setFile] = useState(null);
    const [name, setName] = useState('');
    const [job, setJob] = useState('');
    const [description, setDescription] = useState('');
    // const [imageUrl, setImageUrl] = useState('');
    const [message, setMessage] = useState('');
    const apiurl = "https://back.uttertech.com.et";
    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
      };
    
      const handleNameChange = (e) => {
        setName(e.target.value);
      };
      const handleJobChange = (e) => {
        setJob(e.target.value);
      };
      const handleDescriptionChange = (e) => {
        setDescription(e.target.value);
      };

    
      const handleUpload = async () => {
        const formData = new FormData();
        formData.append('name', name);
        formData.append('job', job);
        formData.append('description', description);
        formData.append('image', file);
    
        try {
          const response = await axios.post(`${apiurl}/api/create_review`, formData)
           
          //   headers: {
          //     'Content-Type': 'multipart/form-data'
          //   }
          // });
          // setImageUrl(`http://localhost:5000/image/${response.data.id}`);
          setMessage(response.data.msg);
          console.log(response.data);
          // navigate('/dashboard/reviews')

        } catch (error) {
          console.error('Error uploading image', error);
          setMessage('Error updating data');
        }
      };
  return (
    <div>
    <input type="text" placeholder="Enter  name"
     onChange={handleNameChange} value={name} />
    <input type="text" placeholder="Enter  job" 
    onChange={handleJobChange} value={job} />
    <textarea type="text" placeholder="Enter description"
     onChange={handleDescriptionChange} value={description} />
    <input type="file" onChange={handleFileChange} />
    <button onClick={handleUpload}>Upload</button>
    {/* {imageUrl && <img src={imageUrl} alt="Uploaded" />} */}
    {message && <p>{message}</p>}
  </div>
  )
}

export default AddReview