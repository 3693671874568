import React from 'react'

const TeamMember = () => {
  return (
    <section className="team_member">
        <br/>
        <br/>
         <h2>Management Team</h2>
         <br/>
        <div className="member">
            <div className="member_list">
           <center> <img src='images/mekonnen.jpg' className='body6_img'/></center>
              <br></br>
              <h2>Mekonnen Fentaw</h2>
               <p><span>CEO</span> & Software Developer</p>
               <p>+251920251598</p>
             
            </div>
            <div className="member_list">
           <center> <img src='images/tsegaw.jpg' className='body6_img'/></center>
              <br></br>
              <h2>Tsegaw Kelela</h2>
               <p> Deputy CEO & Software Developer</p>
               <p>+251911037049</p>
            </div>
            <div className="member_list">
            <center><img src='images/yonas.jpg' className='body6_img'/></center>
              <br></br>
               <h2>Yonas Asmare</h2>
               <p>Web Developer</p>
               <p>+251942332735</p>
            </div>
             <div className="member_list">
            <center><img src='images/temesgen.jpg' className='body6_img'/></center>
              <br></br>
               <h2>Temesgen Sisay</h2>
               <p>Legal Advisor</p>
               <p>+251913304493</p>
            </div>
            
        </div>
     </section>
  )
}

export default TeamMember